import React from 'react';

import { connect } from 'react-redux';

import Banner from 'components/Banner';

import Terms from 'components/Terms';

const TermsPage = () => (
  <div className="page">
    <Terms />
  </div>
);

// connect redux
const mapStateToProps = (state) => ({
  i18n: state.app.i18n
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(TermsPage);
