import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { appSettings } from 'settings';

import Privacypage from 'pages/Privacypage';
import Termspage from 'pages/Termspage';
import Homepage from './pages/Homepage';

const { authCookieName } = appSettings;

export const buildRoutes = (isAuthenticated) => (
  <Switch>
    <Route path="/" component={Homepage} exact />
    <Route path="/voorwaarden" component={Termspage} exact />
    <Route path="/privacy" component={Privacypage} exact />

    {/* <Route component={NotFound} /> */}
  </Switch>
);
