import React from 'react';

import { connect } from 'react-redux';

import Privacy from 'components/Privacy';

const PrivacyPage = () => (
  <div className="page">
    <Privacy />
  </div>
);

// connect redux
const mapStateToProps = (state) => ({
  i18n: state.app.i18n
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivacyPage);
