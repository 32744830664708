import locale from 'browser-locale';
import Cookies from 'js-cookie';

import { Record, List } from 'immutable';

import { I18n } from '@hydra/atom/lib';

import { UPDATE_USER, LOGOUT_USER, UPDATE_LANGUAGE } from '@hydra/atom/lib/actions';

import { appSettings } from 'settings';

const isNL = /nl/i.test(locale());
const val = Cookies.get(appSettings.languageCookieName);

const i18n = new I18n();
i18n.addLanguage('EN', window.LocalizedStringsEN);
i18n.setLanguage(val || (isNL ? 'NL' : 'EN'));

const User = new Record({
  id: null,
  email: null,
  name: null,
  avatar_url: null
});

const State = new Record({
  user: null,
  isAuthenticated: false,
  i18n
});

const initialState = new State();

function appReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_USER: {
      const user = new User(action.user);

      return state.set('isAuthenticated', true)
        .set('user', user);
    }

    case LOGOUT_USER: {
      return state.set('isAuthenticated', false)
        .set('user', null);
    }

    case UPDATE_LANGUAGE: {
      Cookies.set(appSettings.languageCookieName, action.language, { expires: 365 });

      return state.set('i18n', new I18n(action.language));
    }

    default:
      return state;
  }
}

export default appReducer;
