import React from 'react';

import { connect } from 'react-redux';

import Biography from 'components/Biography';
import Banner from '../components/Banner';
import Hero from '../components/Hero';

const Homepage = () => (
  <div className="page homepage">
    <Hero />
    <Biography />
  </div>
);

// connect redux
const mapStateToProps = (state) => ({
  i18n: state.app.i18n
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(Homepage);
