import React from 'react';

import { connect } from 'react-redux';
import $ from 'jquery';

import { Button } from '@hydra/atom/components';

import Logo from './Logo';

const Hero = () => {

  const onButtonClick = () => {
    $('html,body').animate({
      scrollTop: $('.gap').offset().top
    },
    'slow');
  };

  return (
    <div className="container-fluid hero-container">
      <Logo />

      <div className="row">
        <div className="hero">
          <div className="hero-content container">
            <h1>
              Peer review voor advocaten
            </h1>
            <h3>
              Efficiënt voldoen aan verplichte deelname aan gestructureerde feedback
            </h3>
            <Button onClick={onButtonClick} className="button">VERTEL MEER</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

// connect redux
const mapStateToProps = (state) => ({
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(Hero);
