import React from 'react';

import { Link } from 'react-router-dom';

import { connect } from 'react-redux';

const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <footer className="footer">
      <div className="container">
        <div className="row footer-top">
          <div className="col-12 col-md-8 footer-info">
            <h3>Voor advocaten, door advocaten</h3>
            <p>
              peerreview.amsterdam is een initiatief van Legal & Fiscal BV, om te voorzien in de behoefte aan peer review,
              zoals de Nederlandse Orde van Advocaten in maart 2020 binnen haar kwaliteitstoets verplicht stelde. peerreview.amsterdam is gevestigd te 3941 BW  Doorn aan de Moersbergselaan 17, Postbus 186, 3940 AD  Doorn.
            </p>
          </div>
          <div className="col-md-1">&nbsp;</div>
          <div className="col-12 col-md-3 footer-menu">
            <h3>Menu</h3>
            <ul>
              <Link to="/voorwaarden">
                <li>
                  Algemene voorwaarden
                </li>
              </Link>
              <Link to="/privacy">
                <li>
                  Privacy
                </li>
              </Link>
            </ul>
          </div>
        </div>

        <div className="row container">
          <div className="line"></div>
          <p className="copyright">
            © {year}, Legal & Fiscal BV. Alle rechten voorbehouden. peerreview.amsterdam is een handels- en merknaam van Legal & Fiscal BV, KVK 75571072.
          </p>
        </div>
      </div>
    </footer>
  );
};

// connect redux
const mapStateToProps = (state) => ({
  i18n: state.app.i18n
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
