import React from 'react';

import both from '../assets/images/posers_image.png';
import axel from '../assets/images/axel_macro.png';
import hj from '../assets/images/hj_van_der_tak.png';

function Biography() {
  return (
    <div className="container biography">
      <div className="gap"></div>
      <div className="row bio-content">
        <div className="col-span-12">
          <p>
            In 2020 zijn artikel 26 van de Advocatenwet en de Wijzigingsverordening kwaliteitstoetsen in werking getreden. Sindsdien zijn advocaten gehouden om jaarlijks te voldoen aan kwaliteitstoetsen, door deel te nemen aan een vorm van gestructureerde feedback.
            <br />
            <br />
            Gestructureerde feedback is een manier om met vakgenoten te leren van ervaringen, problemen, successen en uitdagingen in de dagelijkse werkpraktijk. Die verdieping van kennis, inzicht en vaardigheden moet leiden tot een effectiever professioneel gedrag. Advocaten kunnen kiezen uit drie vormen van gestructureerde feedback: intervisie, peer review of gestructureerd intercollegiaal overleg.
            <br />
            <br />
            Intervisie en gestructureerd intercollegiaal overleg vinden plaats in een groep van ten minste drie en ten hoogste tien advocaten, dit gedurende ten minste acht uur per jaar. Peer review daarentegen vindt één op één plaats en neemt slechts de helft van de tijd.
            <br />
            <br />
            Peer review is een vorm van gestructureerde feedback, die ziet op de juridische beoordeling van dossiers van een advocaat door een reviewer die eveneens advocaat is. Omdat de reviewer inzage heeft in dossiers van de advocaat is het van belang dat de reviewer aangemerkt wordt als deskundige in de zin van artikel 26 van de Advocatenwet, zodat de in artikel 26, derde lid van de Advocatenwet geregelde geheimhoudingplicht van toepassing is.
            <br />
            <br />
            Blijkens het door de Nederlandse Orde van Advocaten op 7 oktober 2021 op haar site gepubliceerde overzicht zijn tot op heden op 18.000 advocaten slechts dertien advocaten door de NOvA als peer reviewer aangewezen, hetgeen aangeeft hoezeer peer review qua opleidingseis in de kinderschoenen staat.
            <br />
            <br />
          </p>
        </div>

        <div className="row">
          <div className="container">
            <img
              className="img-fluid large-image"
              src={both}
              alt="Axel en HJ" />
          </div>
        </div>

        <div className="col-span-12">
          <p>
            <br />
            <br />
            <b>HJ van der Tak</b> en <b>Axel Macro</b> behoren tot die deskundigen en mogen daardoor als peer reviewer optreden. Zij hebben ieder dan ook rond de 30 jaar ervaring in de advocatuur en hebben recent besloten een deel van hun tijd vrij te maken om confrères en collega’s bij te staan als peer reviewer, waarmee vervolgens door hen aan de verplichtingen van de kwaliteitstoets voldaan kan worden.
            <br />
            <br />
            Peer review kan op uw eigen kantoor plaatsvinden en duurt per jaar dus slechts vier uur. In die tijd worden enkele dossiers bekeken en na afloop volgt een terugkoppeling. Dit in tegenstelling tot beide andere vormen van gestructureerde feedback.
            <br />
            <br />
            Mocht u aan peer review de voorkeur geven boven intervisie, dan kunnen zij u hierbij ten dienste zijn. Discretie en professionaliteit zijn voor hen vanzelfsprekend en geheimhouding is gegarandeerd.
            <br />
            <br />
            De aan een peer review verbonden kosten zijn afhankelijk van een aantal factoren, die persoonlijk met u zullen worden besproken.
            <br />
            <br />
            Voor het maken van een afspraak kunt u hen bereiken op:
            <br />
            <br />
          </p>
        </div>

        <div className="row">
          <div className="col-12 col-md-6 personal-axel">
            <img src={axel} alt="A.P. Macro" className="img-fluid small-image" />
            <div className="contact-info">
              <br />
              <p className="title">mr. A.P. Macro (Axel)</p>
              <a className="email" href="mailto:axel@legal.nl">axel@legal.nl</a>
              <p className="phone">06 51 424 604</p>
            </div>
            <p>
              Axel begon zijn carrière in 1990 bij Pels Rijcken Drooglever Fortuijn. Na inmiddels 30 jaar werkzaam te zijn in de advocatuur kan hij het best omschreven worden als een zeer ervaren allrounder. De laatste jaren heeft hij een gecombineerde proces- en adviespraktijk, en hij wordt met grote regelmaat door (buitenlandse) partijen ingeschakeld om als juridisch klankbord mee te werken aan de totstandkoming van – vaak complexe – transacties.
            </p>
          </div>
          <div className="col-12 col-md-6 col-offset-2 personal-hj">
            <img src={hj} alt="HJ van der Tak" className="img-fluid small-image" />
            <div className="contact-info">
              <br />
              <p className="title">mr. drs. H.J. van der Tak (HJ)</p>
              <a className="email" href="mailto:hj@legal.nl">hj@legal.nl</a>
              <p className="phone">06 33 666 555</p>
            </div>
            <p>
              HJ is sinds 1993 advocaat. Na zijn studies was hij eerder gedurende langere tijd werkzaam op commerciële en bedrijfsjuridische posities in de (inter)nationale film- en muziekindustrie. Na aan het Vondelpark een gecombineerde proces- en adviespraktijk te hebben gevoerd in intellectueel eigendomsrecht, mediarecht en entertainmentrecht, legt HJ van der Tak zich tegenwoordig als advocaat met name toe op verbintenissenrecht en strategiebepaling en begeleiding in IE-aangelegenheden.
            </p>
          </div>
        </div>

        <div className="row intervisie-section">
          <h2>
            Macro en Van der Tak zijn overigens ook gespreksleiders voor intervisiesessies, <a className="intervisie-link" target="_blank" href="https://intervisie.amsterdam/">klik hier</a> als u daar meer informatie over wilt.
          </h2>
        </div>
      </div>
    </div>
  );
}

export default Biography;
