import React from 'react';

import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { Button } from '@hydra/atom/components';

import Logo from './Logo';

const Terms = () => (
  <div className="terms container-fluid">
    <Logo />
    <div className="row container terms-section">
      <div className="col text-section">
        <h1>Algemene voorwaarden</h1>
        <p>
          <h3>1. TOEPASSELIJKHEID</h3>
          peerreview.amsterdam is een site van Legal & Fiscal BV. Deze algemene voorwaarden zijn van toepassing op iedere rechtsverhouding tussen Legal & Fiscal BV en een gebruiker van de site peerreview.amsterdam (de &quot;Site&quot;), alsmede op alle rechtsbetrekkingen en (rechts)handelingen die daaruit voortvloeien. 
          <br />
          <br />

          <h3>2. ACTIVITEITEN</h3>
          peerreview.amsterdam verstrekt informatie over de wijze waarop advocaten zich voor peer review kunnen aanmelden.
          <br />
          <br />

          <h3>3. GEBRUIK VAN DE SITE</h3>
          Advocaten kunnen op geen enkele wijze rechten ontlenen aan de informatie die peerreview.amsterdam verstrekt en publiceert.
          <br />
          <br />

          <h3>4. AANSPRAKELIJKHEID</h3>
          peerreview.amsterdam neemt bij de beveiliging van haar gegevens alle zorg in acht die in redelijkheid van haar kan worden verwacht. peerreview.amsterdam is niet aansprakelijk voor verlies van gegevens of onbevoegde toegang tot gegevens die ontstaat ondanks de door peerreview.amsterdam betrachte zorg.
          <br />
          <br />

          <h3>5. OVERIGE BEPALINGEN</h3>
          De aan peerreview.amsterdam verstrekte informatie en gegevens zullen vertrouwelijk worden behandeld, indien deze als zodanig worden aangeduid of als peerreview.amsterdam redelijkerwijs wist of kon weten dat deze vertrouwelijk zijn.
          <br />
          <br />
          peerreview.amsterdam heeft te allen tijde het recht om de Algemene Voorwaarden te wijzigen. De meest actuele versie van de Algemene Voorwaarden is te vinden op de Site.
          <br />
          <br />
        </p>
        <div className="button-container">
          <Button className="button">
            <Link to="./">TERUG NAAR HOMEPAGE</Link>
          </Button>
        </div>
        <br />
        <br />
        <br />
        <br />
      </div>
    </div>
  </div>
);

// connect redux
const mapStateToProps = () => ({
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(Terms);
