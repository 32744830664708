import React from 'react';

import { Link } from 'react-router-dom';

import { Image, Button } from '@hydra/atom/components';

import logoSVG from '../assets/images/peerreview_logo.svg';

function Logo() {
  return (
    <div className="row header">
      <Link to="/">
        <Image src={logoSVG} alt="Peerreview.amsterdam" />
      </Link>
    </div>
  );
}

export default Logo;
