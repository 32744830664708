import React from 'react';

import { connect } from 'react-redux';

const Banner = () => (
  <div className="container-fluid banner">
    <div className="row">
      <div className="col text-center">
        <p>U kunt zit nu inschrijven voor peer review!</p>
      </div>
    </div>
  </div>
);

// connect redux
const mapStateToProps = () => ({
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(Banner);
