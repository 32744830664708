import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { Image } from '@hydra/atom/components';
import Banner from './Banner';

import logoSVG from '../assets/images/peerreview_logo.svg';

class TopNav extends Component {
  static propTypes = {
    i18n: PropTypes.object.isRequired
  }

  render() {
    const { i18n } = this.props;

    return (
      <nav className="nav">
        <Banner />
      </nav>
    );
  }
}

// connect redux
const mapStateToProps = (state) => ({
  i18n: state.app.i18n
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(TopNav);
